<template>
  <b-container fluid class="d-flex px-0 h-100">
    <b-row no-gutters class="w-100">
      <b-col class="bg-blue overflow-custom" lg="5" order-lg="2">
        <section class="text-white d-flex flex-column flex-lg-column-reverse">
          <div>
            <h3>NOUS CONTACTER</h3>
            <div class="mb-5">
              <p>
                Par téléphone :
                <a href="tel:+33744963020">07 44 96 30 20</a>
              </p>
              <a>
                Par mail :
                <a href="mailto:secretariat.gaujard@gmail.com"
                  >secretariat.gaujard@gmail.com</a
                >
              </a>
            </div>
          </div>
          <div>
            <h3>HORAIRES DU CABINET</h3>
            <div class="mb-5">
              <ul class="list-unstyled">
                <li>Lundi 12h15-18h</li>
                <li>Mardi 8h-15h30</li>
                <li>Jeudi 8h-15h30</li>
                <li>Vendredi 8h-16h</li>
                <li>Fermé le mercredi et le samedi</li>
              </ul>
            </div>
          </div>
          <div>
            <h3>SE RENDRE AU CABINET</h3>
            <div class="mb-5">
              <p>
                Le cabinet du docteur Patrice GAUJARD est situé au
                <br />750, rue Aristide Bergès à Montbonnot-Saint-Martin.
              </p>
              <p>
                Parking reservé au Pôle Santé.
                <br />Bus express 1 et 3, G2 <br />Bus 6021, 6070 arrêt Pré de
                l'eau
              </p>
            </div>
          </div>
        </section>
      </b-col>
      <b-col lg="7" class="p-10 map">
        <tzzz-image
          class="d-lg-block d-none h-100 w-100 position-absolute"
          style="z-index: -100; top:0; left: 0; object-fit: cover;"
          :image="contact"
          :sizes="[
            '100vw',
            '(min-width: 992px) 58vw',
            '(min-width: 2200px) 1284px'
          ]"
        />
        <iframe
          class="bg-light mt-0 w-100 h-100"
          style="min-height: 20rem;"
          src="https://maps.google.com/maps?q=Dr%20Gaujard%20Patrice%2C%20France&t=&z=14&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Contact from "@/assets/images/contact.png?sizes=1284w+1120w+934w+768w+640w";

import TzzzImage from "@/components/TzzzImage.vue";

export default {
  components: { Contact, TzzzImage },
  data() {
    return {
      contact: Contact,
      title: "Contacter le Dr Gaujard, dentiste à Montbonnot",
      description: `Prenez rendez-vous au 04 76 77 53 69. Le cabinet se situe au Pôle Santé, 750 rue Aristide Bergès à Montbonnot-Saint-Martin, parking réservé, accès bus 1, 3 G2.`
    };
  }
};
</script>

<style scoped>
.bg-blue {
  background-color: var(--blue);
}
/* .map {
  background: url("../assets/images/contact.png") center no-repeat;
  background-size: cover;
} */
@media (min-width: 992px) {
  .p-10 {
    padding: 8rem 3rem 3rem;
  }
}
@media (min-width: 1200px) {
  .p-10 {
    padding: 9rem 5rem 5rem;
  }
}
section {
  padding: 8rem 2rem 1rem 2rem;
  font-size: 1.4rem;
  font-weight: 400;
}
@media (min-width: 992px) {
  section {
    padding: 8rem 3rem 6rem 3rem;
  }
}
h3 {
  margin-bottom: 2rem;
}
h3:after {
  content: "";
  display: block;
  width: 5rem;
  margin-top: 12px;
  border: 3px solid var(--white);
}
</style>
