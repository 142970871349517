
module.exports = {
  sources: 
{
  "2400w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=2400!/workspace/src/assets/images/home.jpg?size=2400w"),
"1920w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1920!/workspace/src/assets/images/home.jpg?size=1920w"),
"1600w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1600!/workspace/src/assets/images/home.jpg?size=1600w"),
"1366w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1366!/workspace/src/assets/images/home.jpg?size=1366w"),
"1024w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1024!/workspace/src/assets/images/home.jpg?size=1024w"),
"768w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=768!/workspace/src/assets/images/home.jpg?size=768w"),
"640w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=640!/workspace/src/assets/images/home.jpg?size=640w")
}
,
  srcSet: require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=2400!/workspace/src/assets/images/home.jpg?size=2400w") + " 2400w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1920!/workspace/src/assets/images/home.jpg?size=1920w") + " 1920w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1600!/workspace/src/assets/images/home.jpg?size=1600w") + " 1600w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1366!/workspace/src/assets/images/home.jpg?size=1366w") + " 1366w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1024!/workspace/src/assets/images/home.jpg?size=1024w") + " 1024w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=768!/workspace/src/assets/images/home.jpg?size=768w") + " 768w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=640!/workspace/src/assets/images/home.jpg?size=640w") + " 640w",
  placeholder: require('!!/workspace/node_modules/srcset-loader/lib/placeholder-loader?lightweight=false!/workspace/src/assets/images/home.jpg'),
};
