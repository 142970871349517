
module.exports = {
  sources: 
{
  "1284w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1284!/workspace/src/assets/images/team-2.jpg?size=1284w"),
"1120w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1120!/workspace/src/assets/images/team-2.jpg?size=1120w"),
"934w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=934!/workspace/src/assets/images/team-2.jpg?size=934w"),
"768w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=768!/workspace/src/assets/images/team-2.jpg?size=768w"),
"640w": require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=640!/workspace/src/assets/images/team-2.jpg?size=640w")
}
,
  srcSet: require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1284!/workspace/src/assets/images/team-2.jpg?size=1284w") + " 1284w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=1120!/workspace/src/assets/images/team-2.jpg?size=1120w") + " 1120w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=934!/workspace/src/assets/images/team-2.jpg?size=934w") + " 934w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=768!/workspace/src/assets/images/team-2.jpg?size=768w") + " 768w"+","+require("-!/workspace/node_modules/url-loader/dist/cjs.js??ref--14-0!/workspace/node_modules/image-webpack-loader/index.js!/workspace/node_modules/srcset-loader/lib/resize-loader?size=640!/workspace/src/assets/images/team-2.jpg?size=640w") + " 640w",
  placeholder: require('!!/workspace/node_modules/srcset-loader/lib/placeholder-loader?lightweight=false!/workspace/src/assets/images/team-2.jpg'),
};
